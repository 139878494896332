function loadingTemplate(props) {
  return `
  <div class="${props.styles.wrapper}">
  <div
    class="${props.styles.loader} ${props.styles.active}"
    data-hook="loader"
    >
    </div>
  </div>`;
}

function overlayTemplate(props) {
  return `<div
    class="${props.styles.overlay} ${props.styles.active}"
    data-hook="overlay"
    >
    <div
      class="${props.styles.poster}"
      data-hook="overlay-content"
      >
    </div>
    <div
      class="${props.styles.icon}"
      data-hook="overlay-play-button"
      >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        preserveAspectRatio="xMidYMin slice"
        width="100%"
        style="padding-bottom: 100%; height: 1px; overflow: visible"
        >
        <!-- padding-bottom: 100% * height/width -->
        <g
          fill="none"
          fill-rule="evenodd"
          >
          <circle
            cx="18"
            cy="18"
            r="17"
            class="${props.styles.svgStroke}"
            stroke-width="2"
            />
          <path
            class="${props.styles.svgFill}"
            d="M23.935 17.708l-10.313 6.033V11.676z"
            />
        </g>
      </svg>
    </div>
  </div>`;
}

export { loadingTemplate, overlayTemplate };
