exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".B1lGL{position:absolute;z-index:100;top:0;right:0;bottom:0;left:0;display:none}.B1lGL._1URtI{display:flex;justify-content:center;align-items:center}._1uwOI{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;background:black no-repeat center;background-size:cover;justify-content:center;align-items:center}._1uwOI:before{position:absolute;top:0;left:0;width:100%;height:100%;content:'';background-color:rgba(0,0,0,0.35)}.AQ0UB{position:relative;width:71px;height:71px;cursor:pointer;opacity:1}.AQ0UB:hover{opacity:.8}._3CKqa{fill:white}._2T3h2{stroke:white}\n", ""]);

// Exports
exports.locals = {
	"overlay": "B1lGL",
	"active": "_1URtI",
	"poster": "_1uwOI",
	"icon": "AQ0UB",
	"svgFill": "_3CKqa",
	"svgStroke": "_2T3h2"
};