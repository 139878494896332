import Playable from 'playable';
import HLSAdapter from 'playable/dist/src/adapters/hls';
import {
  getInfo,
  getVideoMetadata,
  getVideoURLs,
  getVideoImage,
} from '@wix/video-gallery-sdk';

import { IWixMGPlayer } from './typings/types';
import WixMGPlayerUI from './ui/ui';

Playable.registerPlaybackAdapter(HLSAdapter);

const ADAPTIVE_MIN_DURATION = 30000;

function filterSource(sources: any[], duration: number) {
  if (duration > ADAPTIVE_MIN_DURATION) {
    return sources.filter(source => source.quality === 'ADAPTIVE');
  } else {
    return sources
      .filter(source => source.quality !== 'ADAPTIVE')
      .sort(
        (source1, source2) =>
          parseInt(source1.quality, 10) > parseInt(source2.quality, 10) ? 0 : 1,
      );
  }
}

/**
 * Create new instance of Wix MG Player
 *
 * @doc API
 * @param id - Video ID from Media Manager
 * @param params - Playable configuration. Ream more [here](https://wix.github.io/playable/player-config)
 * @example
 * import * as WixMGPlayer from 'wixmg-player';
 *
 * const videoId = '64b2fa_039e5c16db504dbaad166ba28d377744';
 * const mgPlayer = WixMGPlayer.create(videoId, {
 *   size: {
 *     width: 320,
 *     height: 160
 *   }
 * });
 *
 * // If you want to use full playable API, use player reference.
 * // Read more about API here: https://wix.github.io/playable/api
 * // For example:
 * mgPlayer.player.togglePlayback();
 */
export function create(id: string, params: any = {}): IWixMGPlayer {
  const {
    size: { width, height } = { width: null, height: null },
    fillAllSpace,
  } = params;

  const forcedPoster = params.overlay && params.overlay.poster;

  const player = Playable.create({
    ...params,
    overlay: false,
  });

  const ui = new WixMGPlayerUI(width, height, fillAllSpace, {
    onPlayClick: () => {
      ui.hideOverlay();
      player.play();
    },
  });

  ui.attachPlayer(player);

  player.on(Playable.ENGINE_STATES.PLAYING, () => {
    ui.hideOverlay();
  });

  player.on(Playable.ENGINE_STATES.ENDED, () => {
    ui.showOverlay();
  });

  const setID = (id: string) => {
    player.pause();

    ui.showLoader();

    if (forcedPoster) {
      ui.setPoster(forcedPoster);
    } else {
      const poster =
        width &&
        height &&
        getVideoImage(id, { width, height, previewIndex: 1 });
      ui.setPoster(poster);
    }

    ui.showOverlay();

    return getInfo(id).then(info => {
      return Promise.all([getVideoURLs(info), getVideoMetadata(info)]).then(
        ([urls, metadata]) => {
          ui.hideLoader();
          player.setSrc(filterSource(urls, metadata.duration)[0]);
        },
      );
    });
  };

  const destroy = () => {
    player.destroy();
    ui.destroy();
  }

  setID(id);

  return {
    setID,
    play: player.play,
    pause: player.pause,
    attachToElement: node => ui.attachToElement(node),
    destroy,
    player,
  };
}
