import overlayStyles from './overlay.scss';
import loaderStyles from './loader.scss';
import playerContainerStyles from './player-container.scss';
import { overlayTemplate, loadingTemplate } from './templates';
import { htmlToElement } from './utils';

class WixMGPlayerUI {
  rootNode: HTMLElement;
  overlay: HTMLElement;
  loader: HTMLElement;
  playerContainer: HTMLElement;
  callbacks: any;

  constructor(width, height, fillAllSpace, callbacks) {
    this.callbacks = callbacks;
    this._initUI(width, height, fillAllSpace);
    this._bindEventListeners();
  }

  _initUI(width, height, fillAllSpace) {
    this.overlay = htmlToElement(
      overlayTemplate({
        styles: overlayStyles,
      }),
    );

    this.loader = htmlToElement(
      loadingTemplate({
        styles: loaderStyles,
      }),
    );

    this.playerContainer = document.createElement('div');
    this.playerContainer.setAttribute('data-player-container', 'true');
    this.playerContainer.classList.add(playerContainerStyles.playerContainer);

    this.rootNode = document.createElement('div');
    this.rootNode.classList.add(playerContainerStyles.mainContainer);

    if (fillAllSpace) {
      this.rootNode.style.width = '100%';
      this.rootNode.style.height = '100%';
    } else {
      this.rootNode.style.width = width ? `${width}px` : '';
      this.rootNode.style.height = height ? `${height}px` : '';
    }

    this.rootNode.appendChild(this.overlay);
    this.rootNode.appendChild(this.loader);
    this.rootNode.appendChild(this.playerContainer);
  }


  _bindEventListeners() {
    this.overlay
      .querySelector('[data-hook="overlay-play-button"]')
      .addEventListener('click', this.callbacks.onPlayClick);
  }

  _unbindEventListeners() {
    this.overlay
      .querySelector('[data-hook="overlay-play-button"]')
      .removeEventListener('click', this.callbacks.onPlayClick);
  }

  setPoster(poster) {
    (this.overlay.querySelector(
      '[data-hook="overlay-content"]',
    ) as HTMLElement).style.backgroundImage = poster ? `url(${poster})` : '';
  }

  hideOverlay() {
    this.overlay.style.display = 'none';
  }

  showOverlay() {
    this.overlay.style.display = 'flex';
  }

  hideLoader() {
    this.loader.style.display = 'none';
  }

  showLoader() {
    this.loader.style.display = 'flex';
  }

  attachToElement(node: HTMLElement) {
    node.appendChild(this.rootNode);
  }

  attachPlayer(player) {
    player.attachToElement(this.playerContainer);
  }

  destroy() {
    this._unbindEventListeners();

    if (this.rootNode.parentNode) {
      this.rootNode.parentNode.removeChild(this.rootNode);
    }

    this.rootNode = null;
    this.loader = null;
    this.overlay = null;
    this.playerContainer = null;
    this.callbacks = null;
  }
}

export default WixMGPlayerUI;
